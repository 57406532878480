a.see-more {
  font-size: 20px;
}

@media (max-width: 992px) {
  .time {
    display: none;
  }
}

.loader {
  height: 100vh;
}

.blank-filler {
  flex: 1;
}
